import {
    Container 
} from "./styles";

function Button(props) {
    return (
        <Container onClick={props.onClick} style={props.style} outline={props.outline}>
            { props.children }
        </Container>
    )
};

export { Button };