import { useState } from "react";

import { Button } from "../Button";
import {
    HeaderContent,
    TextsContainer,
    Title,
    Subtitle,
    ButtonsContainer,
    Text,
    MobileText,
    Card,
    CardBody,
    CardImage,
    CardHeader,
    CardTitle,
    ArrowIcon,
    ModalContent,
} from "./styles";

import HomeShield, { MobileHomeShield } from '../../components/HomeComponents'
import { Modal } from "../Modal";

import AssistantImg from '../../img/mechanic.png'
import AgentImg from '../../img/policeman.png'
import Seta from '../../img/Seta.png'

function Header() {
    const [openModal, setOpenModal] = useState(false);

    function renderModalContent() {
        
        const handleClick2 = () => {
            window.open("https://my.forms.app/form/61eafac734a60c26308824f7");
          };
          const handleClick1 = () => {
            window.open("https://my.forms.app/form/61eaf314ed8dd6152819d216");
          };
        return (
            <ModalContent>
                <Card onClick={handleClick1}> 
                    <CardHeader >
                        <CardImage src={AssistantImg} />
                    </CardHeader>
                    <CardBody>
                        <CardTitle>Seja um Prestador de Serviços Assistência 24 hs</CardTitle>

                        <ArrowIcon src={Seta} />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <CardImage src={AgentImg} onClick={handleClick2}/>
                    </CardHeader>
                    <CardBody>
                        <CardTitle>Torne-se um Agente de Pronta Resposta</CardTitle>

                        <ArrowIcon src={Seta} />
                    </CardBody>
                </Card>
            </ModalContent>
        )
    }

    const handleClick = () => {
        window.open("https://api.whatsapp.com/send?phone=5511984715242&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20contratar%20a%20PRMA.");
      };

    
    return (
        <HeaderContent>
            <TextsContainer>
                <Title>
                Pronta Resposta, Monitoramento e Assistência 24hs
                </Title>
                <Subtitle>
                    Atendimento de qualidade e excelência 24 horas por dia, 7 dias por semana.
                </Subtitle>
                <ButtonsContainer>
                    <Button style={{ marginRight: 10 }} onClick={handleClick}>
                        <Text>Contratar a PRMA</Text>
                        <MobileText>Contratar</MobileText>
                    </Button>
                    <Button outline onClick={() => setOpenModal(true)}>
                        <Text>Seja um prestador</Text>
                        <MobileText>Ser prestador</MobileText>
                    </Button>
                </ButtonsContainer>
                <MobileHomeShield />
            </TextsContainer>
            <div>
                <HomeShield />
            </div>

            <Modal withoutBackground open={openModal} handleClose={() => setOpenModal(false)}>
                {renderModalContent()}
            </Modal>
        </HeaderContent> 
    )
};

export { Header };