import styled from "styled-components";

export const Container = styled.div`
    background: #242424;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 200px;

    position: relative;

    > div {
        width: 33%;

        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
            flex-direction: column;
            align-items: flex-start;
        }

        &:last-of-type {
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 2rem 1.4375rem;

        margin-top: 80px;

        > div:first-of-type {
            width: 100%;

            z-index: 5;
        }
    }
`;

export const ShieldContainer = styled.div`
    height: 300px;
`;

export const Title = styled.h1`
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 2.3rem;

    color: #FFF;

    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
        font-size: 1.875rem;
        margin-bottom: 1.9rem;
    }
`;

export const ShieldLogo = styled.img`
    height: 100%;

    @media (max-width: 768px) {
        position: absolute;

        right: -60px;

        z-index: 1;

        height: 75%;
    }
`;

export const Row = styled.div`
    display: flex;
`;

export const Icon = styled.img`
    width: 100%;
`;

export const IconContainer = styled.div`
    width: 50px;
    height: 50px;

    padding: 0.5rem;

    border-radius: 4px;

    background: #191919;

    margin: 10px;
`;