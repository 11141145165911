import styled from "styled-components";

export const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 0 !important;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden !important;

    z-index: 10;
`;

export const ModalContent = styled.div`
    max-width: 80vw;
    max-height: 90vh;

    -webkit-animation: fadein 0.4s linear forwards;
    animation: fadein 0.4s linear forwards;

    background: ${ props => props.withoutBackground ? "transparent" : "#191919"};

    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @media (max-width: 768px) {
        max-width: 100vw;
        max-height: 100vh;

        overflow-y: auto;
    }
`;