import styled from "styled-components";

export const Container = styled.div`
    width: 55vw;

    color: white;

    padding: 35px;

    position: relative;

    @media (max-width: 768px) {
        flex: 1;
        width: 80vw;
        height: 100%;
    }
`;

export const Icon = styled.img`
    width: 60px;
    height: 60px;

    background: #242424;
    border-radius: 4px;

    margin-right: 1.3rem;

    padding: 15px;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;

export const Header = styled.div`
    display: flex;
    font-size: 1.5rem;
    font-family: 'DM Sans', sans-serif;

    align-self: stretch;
    align-items: center;

    justify-content: space-between;

    span {
        width: 50%;

        @media (max-width: 768px) {
            font-size: 1.3rem;
        }
    }
`;

export const CloseIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;

    top: 16px;
    right: 16px;

    position: absolute;

    cursor: pointer;
` 

export const Body = styled.div`
    p {
        font-size: 1rem;
        line-height: 26px;
        color: #B8B8B8;

        font-family: 'Inter', sans-serif;
        font-weight: 400;

        margin-top: 2.5rem;
        margin-bottom: 1rem;
    }
`;

export const BenefitList = styled.div`
  display: flex;
  flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-wrap: normal;
        flex-direction: column;
    }
`;

export const BenefitCard = styled.div`
    display: flex;
    align-items: center;
    
    flex-grow: 1;
    width: 33%;

    margin-top: 24px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const BenefitCardIcon = styled.img`
    border-radius: 4px;

    background: #242424;

    padding: 10px;

    margin-right: 1rem;
`;

export const BenefitCardTitle = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: light;
    font-size: 0.9rem;

    width: 50%;
`;