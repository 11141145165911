import { ModalContent, Overlay } from "./style";

function Modal(props) {
    function handleClick(event) {
        const id = event.target.id;

        if (id === "modal-overlay") props.handleClose();
    }

    if (!props.open) return <></>;

    return (
        <Overlay id="modal-overlay" onClick={handleClick}>
            <ModalContent withoutBackground={props.withoutBackground} id="modal-body">
                { props.children }
            </ModalContent>
        </Overlay>
    )
};

export { Modal };