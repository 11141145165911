import styled from "styled-components";

export const Container = styled.button`
    border: 0;
    border-radius: 4px;

    font-family: DM Sans;
    font-size: 1.125rem;
    font-weight: 500;

    padding: 1rem 1.7rem;

    cursor: pointer;

    transition: background 0.4s, border-color 0.4s, color 0.4s;

    ${ props => 
        props.outline ? 
        `
            background: transparent;
            border: 1px solid #FFF;
            color: #FFF;
        ` :
        `
            background: #FF8F01;
            color: #191919;
        `
    }

    &:hover {
        ${ props => 
            props.outline ? 
            `
                color: #f7b259;
                border-color: #f7b259;
            ` :
            `
                background: #f7b259;
            `
        }
    }

    @media (max-width: 768px) {
        font-size: 1rem;

        padding: 1rem 1.4rem;
    }
`;