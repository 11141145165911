import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    margin-top: 200px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;

        margin-top: 80px;

        padding: 0px 15px;
    }
`;

export const Title = styled.h1`
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 2.3rem;

    color: #FFF;

    @media (max-width: 768px) {
        font-size: 1.875rem
    }
`;

export const Description = styled.p`
    color: #B8B8B8;

    font-weight: normal;
    font-size: 1.15rem;

    margin-top: 1.5rem;

    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
        font-size: 0.875rem;

        line-height: 24px;
    }
`;

export const BannerContainer = styled.div`
    position: relative;
`;

export const Banner = styled.img`
    margin-right: 1rem;
    width: 100%;
    border-radius: 4px;
`;

export const InfoContainer = styled.div`
    display: flex;
    align-self: stretch;

    position: relative;

    flex-direction: column;
    justify-content: center;

    padding: 0rem 2rem;
    width: 50%;
    @media (max-width: 768px) {
        margin-top: 48px;

        padding: 0;
        width: unset;

    }
`;

export const Icon = styled.img`
    position: absolute;

    right: -20px;
    top: 30px;

    @media (max-width: 768px) {
        right: 20px;
        top: -20px;

        width: 64px;
        height: 64px;
    }
`;