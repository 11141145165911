import {
    Container,
    Title,
    Description,
    BannerContainer,
    Banner,
    InfoContainer,
    Icon
} from "./styles";

import AssistantBackground from '../../img/assistantImg.svg'

import CardIcon from '../../img/card.png'
import { Button } from "../Button";

function BeAnAssistant() {
    const handleClick1 = () => {
        window.open("https://my.forms.app/form/61eaf314ed8dd6152819d216");
      };
    return (
        <Container>
            <BannerContainer>
                <div style={{flexShrink: .5}}>
                    <Banner src={AssistantBackground} />
                </div>
                <Icon src={CardIcon} />
            </BannerContainer>
            <InfoContainer>
                <Title>Venha fazer parte do nosso time de prestadores</Title>
                <Description>
                    Preencha o formulário clicando o botão abaixo, que nossa equipe de gestão de serviço fará contato para fazer seu credenciamento.
                </Description>

                <Button onClick={handleClick1}>
                    Quero ser um prestador
                </Button>
            </InfoContainer>
        </Container>
    )
};

export { BeAnAssistant };