import {HamburgerContainer, HeaderContainer, LogoCompany, LogoCompanyPRMA, LogoContainer, MobileContainer, MobileNavBar, NavBar} from './style'
import Logo1 from '../../img/Logo1.png'
import LogoPRMA from '../../img/LogoPRMA.png'
import React, { useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import Hamburger from 'hamburger-react';

export function Navbar() {
    const [open, setOpen] = useState(false)

    function closeHamburguer() {
        setOpen(false);
    }

    const scrollToElement = (elementId) => {
        document.getElementById(elementId).scrollIntoView({block: 'start'})
    }

    return (
        <HeaderContainer>
            <LogoContainer>
                <LogoCompany src={Logo1} />
                <LogoCompanyPRMA src={LogoPRMA} />
            </LogoContainer>
            <NavBar>
                <a onClick={() => scrollToElement('inicio')} class="inicio">Inicio</a>
                <a onClick={() => scrollToElement('services')} class="servicos">Serviços</a>
                <a onClick={() => scrollToElement('aboutUs')} class="quem-somos">Quem somos</a>
                {/* <Link to='/aplicativo'>Aplicativo</Link> */}
                <a onClick={() => scrollToElement('contact')} class="contato">Contato</a>
            </NavBar>
            <MobileContainer>
                <HamburgerContainer>
                    <Hamburger direction="right" size={30} toggled={open} toggle={setOpen} >
                    </Hamburger>
                </HamburgerContainer>
                {open && 
                    (
                        <MobileNavBar>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <a onClick={() => {scrollToElement('inicio');closeHamburguer()}} class="mobile-navbar">Inicio</a>
                                <a onClick={() => {scrollToElement('services');closeHamburguer()}} class="mobile-navbar">Serviços</a>
                                <a onClick={() => {scrollToElement('aboutUs');closeHamburguer()}} class="mobile-navbar">Quem somos</a>
                                {/* <Link to='/aplicativo'>Aplicativo</Link> */}
                                <a onClick={() => {scrollToElement('contact');closeHamburguer()}}  class="mobile-navbar">Contato</a> 
                            </div>
                        </MobileNavBar>
                    )}
            </MobileContainer>
        </HeaderContainer>
    )
    }