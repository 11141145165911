import {
    CloseIcon,
    Container,
    Header,
    Icon,
    Body,
    BenefitCard,
    BenefitCardIcon,
    BenefitCardTitle,
    BenefitList
} from "./styles";

import closeIcon from "../../img/close-small.svg";

function ServiceModal(props) {
    const service = props.service;

    return (
        <Container>
            <Header>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon src={service.icon}/>
                    <span>{service.title}</span>
                </div>
            </Header>

            <CloseIcon onClick={() => props.handleClose()} src={closeIcon} />

            <Body>
                <p>{service.description}</p>

                <BenefitList>
                    {service.benefits.map((benefit, index) => (
                        <BenefitCard key={index}>
                            <BenefitCardIcon src={benefit.icon} />
                            <BenefitCardTitle>{benefit.name}</BenefitCardTitle>
                        </BenefitCard>
                    ))}
                </BenefitList>
            </Body>
        </Container>
    )
};

export { ServiceModal };