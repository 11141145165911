import styled from "styled-components";

export const HeaderContainer = styled.div`
position: sticky;
top: 0;
z-index: 100;
background: #191919;
opacity: .8;
display: flex;
justify-content: space-between;
align-self: stretch;
align-items: center;
height: 100px;
@media(max-width:768px){
  /* width: 100%; */
  height: 100%;

  padding: 1rem 0.9375rem;
}
`
export const LogoContainer = styled.div`
display: flex;
align-items: center;
`

export const LogoCompany = styled.img`
position: relative;
width: 53.54px;
height: 38px;
margin-right: 17.5px;
  @media (max-width: 768px) {
    width: 39.23px;
    height: 28px;
    margin-right: 0;
    /* margin-left: 20px; */
  }
}
`

export const LogoCompanyPRMA = styled.img`
  position: relative;
  width: 160.62px;
  height: 15.65px;
  @media (max-width: 768px){
    width: 117.69px;
    height: 11.53px;
    margin-left: 13px;
  }
`

export const NavBar = styled.div`
display: flex;
position: relative;
font-size: 16px;
line-height: 26px;
color: #B8B8B8;
margin-left: auto;
@media(max-width: 768px){
  display: none;
}

.inicio{
  position: relative;
  width: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #B8B8B8;
}

.servicos{
  position: relative;
width: 66px;
font-weight: normal;
font-size: 16px;
line-height: 26px;
text-decoration: none;
color: #B8B8B8;
margin-left: 32px;
}

.quem-somos{
  position: relative;
width: 110px;
font-size: 16px;
line-height: 26px;
text-decoration: none;
color: #B8B8B8;
margin-left: 32px;
}

.contato{
  position: relative;
  width: 61px;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #B8B8B8;
  margin-left: 32px;
    font-family: 'Inter', sans-serif;
}

`
export const HamburgerContainer = styled.div`
  display: none;

  z-index: 50;
  @media(max-width: 768px){
    display: block;
    color: white;
  }
`

export const MobileNavBar = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 1);

  margin: 0 !important;

  z-index: 11;

  overflow: hidden !important;

  .mobile-navbar {
    text-decoration: none;
    color: white;
    text-align: center;
    /* padding: 5px 0 5px 0; */

    padding: 0.7rem 0rem;
  }
`
export const MobileContainer = styled.div`
  @media(max-width: 768px){
    display: flex;
    justify-content: space-between;
  }
`