import { CarIcon, CardContainer, Container, LeftArrow, Rectangle, RightArrow, Subtitle, Title, Card, Location, LocationContainer, Location2, Location3, Location4, DateContainer, Date, Overflow, TitleContainer, TextContainer, Description, UpperContainer } from "./style";
import Car1 from '../../img/car 1.svg'
import Arrow from '../../img/Arrow.svg'
import Arrow2 from '../../img/Arrow2.svg'
import RecoveredCar1 from '../../img/Veiculos/Busca1.jpeg'
import RecoveredCar2 from '../../img/Veiculos/Busca2.jpeg'
import RecoveredCar3 from '../../img/Veiculos/Busca3.jpeg'
import Recoveredfinaly from '../../img/Veiculos/final.png'

export default function RecoveredCars() {
    return (
    <Container>
        <TitleContainer>
        <UpperContainer>
        <CarIcon src={Car1} />
        <TextContainer>
        <Subtitle>
        Pronta resposta veicular
        </Subtitle>
        <Title>
        Veículos recuperados
        </Title>
        </TextContainer>
        </UpperContainer>
        <div>
        <LeftArrow src={Arrow} />
        <RightArrow src={Arrow2} />
        </div>
        </TitleContainer>

        <Overflow>
        <CardContainer>
        <Card src={RecoveredCar1} />
        <Card src={RecoveredCar2} />
        <Card src={RecoveredCar3} />
        <Card src={Recoveredfinaly} />
        </CardContainer>
        </Overflow>
    </Container>

    )

}