import {
    Container,
    Description,
    Header,
    Title,
    OrangeArrow,
    OrangeDescription,
    Background,
    Body,
    InfoCard,
    InfoCardBody,
    InfoCardDescription,
    InfoCardTitle,
    CircleIcon,
    InfoCardHeader,
} from "./styles";

import orangeArrow from '../../img/Line 7.png'

import background from '../../img/logo24h.png'
import { InfoList } from "./data";

function AboutUs() {
    return (
        <Container id="aboutUs">
            <Background src={background} />
            <Header>
                <div>
                    <Title>O que é a PRMA Federal?</Title>

                    <div style={{ marginTop: 30 }}>
                        <Description>Somos uma empresa B2B, situada em São Paulo, com equipe em 26 Estados além do Distrito Federal. Nosso propósito é oferecer atendimento de qualidade e excelência 24 horas por dia, 7 dias por semana, e ser a melhor opção para os nossos clientes.</Description>
                    </div>
                </div>
                <div>
                    <Title>+20 anos</Title>

                    <div style={{ marginTop: 30 }}>
                        <OrangeArrow src={orangeArrow} />

                        <OrangeDescription>Profissionais com expertise e experiência no mercado</OrangeDescription>
                    </div>
                </div>
            </Header>

            <Body>
                {InfoList.map((info, index) => (
                    <InfoCard key={index}>
                        <div>
                            <CircleIcon />
                        </div>
                        <InfoCardBody>
                            <InfoCardHeader>
                                <InfoCardTitle>{info.title}</InfoCardTitle>
                            </InfoCardHeader>
                            <InfoCardDescription>{info.description}</InfoCardDescription>
                        </InfoCardBody>
                    </InfoCard>
                ))}
            </Body>
        </Container>
    )
};

export { AboutUs };