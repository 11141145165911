import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
    *{
        margin: 0;
        padding: 0;
    }
    
    html, body, #root{
        font-family: 'Inter', 'DM Sans', sans-serif;

        @media(max-width:768px){
            overflow-x: hidden;
        }
    }


    
    .App {
        position: relative;
        width: 100vw;
        height: 100vh;
        background: #191919;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
    }    

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #242424;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #FF8F01;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`