import styled from "styled-components";

export const CardButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    padding: 1rem 0rem;
    padding-right: 2rem;

    background: #191919;

    margin-right: 1px;
    margin-bottom: 1px;

    flex-grow: 1;
    width: 29%;

    @media (max-width: 768px) {
        width: 100%;

        padding: 0.6rem 1rem;
    }

    @media (min-width: 992px) { ... }


    @media (min-width: 1200px) { ... }

    @media (min-width: 1400px) { ... }
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.img`
    max-width: 40px;
`;

export const IconContainer = styled.div`
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;

    border-radius: 4px;

    background: #242424;

    margin-right: 1.4rem;
`;

export const Text = styled.span`
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 1.05rem;
    color: #FFF;

    width: 50%;
`;

export const ArrowIcon = styled.img`
    width: 6px;
    height: 12px;
`;