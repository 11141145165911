// assets do Central de Monitoramento
import Eye from "../../img/benefits/central/eye.svg"; // Ícone

import CentralBatteryCharge from "../../img/benefits/central/battery-charge.svg";
import CentralBatteryWorking from "../../img/benefits/central/battery-working.svg";
import CentralBlock from "../../img/benefits/central/block.svg";
import CentralCaution from "../../img/benefits/central/caution.svg";
import CentralMessage from "../../img/benefits/central/message.svg";
import CentralPin from "../../img/benefits/central/pin.svg";
import CentralPoint from "../../img/benefits/central/point.svg";
import CentralScreen from "../../img/benefits/central/screen.svg";
import CentralSiren from "../../img/benefits/central/siren.svg";

// assets do Pronta resposta veicular
import Car from "../../img/benefits/veicular-patrimony/car.svg";

import ProntaVeicularBalance from "../../img/benefits/veicular-patrimony/balance.svg";
import ProntaVeicularFile from "../../img/benefits/veicular-patrimony/file-focus.svg";
import ProntaVeicularRefresh from "../../img/benefits/veicular-patrimony/refresh.svg";

// assets de Pronta resposta patrimonial
import Home from "../../img/benefits/residencial-patrimony/home.svg";

import AssistanceLeftArrow from "../../img/benefits/veicular-assistance/arrow-left.svg";
import AssistanceTool from "../../img/benefits/veicular-assistance/tool.svg";
import AssistanceBattery from "../../img/benefits/veicular-assistance/battery-working.svg";
import AssistanceTire from "../../img/benefits/veicular-assistance/circle.svg";
import AssistanceWaterLevel from "../../img/benefits/veicular-assistance/water-level.svg";
import AssistanceCar from "../../img/benefits/veicular-assistance/car.svg";

// assets de Acompanhamento jurídico
import Balance from "../../img/benefits/legal-monitoring/balance.svg";

import LegalEye from "../../img/benefits/legal-monitoring/eye.svg";
import LegalFile from "../../img/benefits/legal-monitoring/file-addition.svg";
import LegalLock from "../../img/benefits/legal-monitoring/lock.svg";

import ProntaResidencialLike from "../../img/benefits/residencial-patrimony/like.svg";
import ProntaResidencialSiren from "../../img/benefits/residencial-patrimony/siren.svg";
import ProntaResidencialCar from "../../img/benefits/residencial-patrimony/car.svg";

import Shield from '../../img/protect 1.svg'
import File from '../../img/file-focus 1.svg'


export const BenefitList = [
    {
        id: Math.random(),
        icon: Eye,
        title: "Central de Monitoramento",
        description: "Monitoramos e tratamos os alertas, gerados na plataforma de monitoramento veicular 24 horas por dia, 7 dias por semana, durante 365 dias do ano.",
        benefits: [
            {
                id: Math.random(),
                name: "Carga de Bateria",
                icon: CentralBatteryWorking
            },
            {
                id: Math.random(),
                name: "Alerta de violação do painel",
                icon: CentralSiren
            },
            {
                id: Math.random(),
                name: "Equipamento sem comunicação",
                icon: CentralMessage
            },
            {
                id: Math.random(),
                name: "Bateria violada",
                icon: CentralBatteryCharge
            },
            {
                id: Math.random(),
                name: "Central de aviso 24hs",
                icon: CentralScreen
            },
            {
                id: Math.random(),
                name: "Saída de cerca não autorizada",
                icon: CentralPin
            },
            {
                id: Math.random(),
                name: "Acionamento de botão de pânico",
                icon: CentralPoint
            },
            {
                id: Math.random(),
                name: "Alerta de Jammer",
                icon: CentralCaution
            },
            {
                id: Math.random(),
                name: "Bloqueio",
                icon: CentralBlock
            }
        ]
    },
    {
        id: Math.random(),
        icon: Shield,
        title: "Pronta Resposta Veicular",
        description: "Contamos com uma equipe de apoio tático, distribuída em pontos estratégicos. Nossa tecnologia garante rápida interligação entre nossa central de monitoramento e nossa equipe de agentes, resultando em uma melhor eficiência em nossas ações de recuperação proveniente de roubo ou furto de veículos e cargas.",
        benefits: [
            {
                id: Math.random(),
                name: "Recuperação em caso de roubo e furto",
                icon: ProntaVeicularRefresh
            },
            {
                id: Math.random(),
                name: "Sindicância",
                icon: ProntaVeicularFile
            },
            {
                id: Math.random(),
                name: "Auxílio Jurídico",
                icon: ProntaVeicularBalance
            }
        ]
    },
    {
        id: Math.random(),
        icon: Home,
        title: "Pronta Resposta Patrimonial",
        description: "Com o objetivo de preservar a integridade do imóvel, a equipe de agentes da PRMA Federal, faz a inspeção para verificação de eventuais danos e intrusões buscando garantir a segurança até o termino da operação.",
        benefits: [
            {
                id: Math.random(),
                name: "Vistoria de disparos de alarmes",
                icon: ProntaResidencialSiren
            },
            {
                id: Math.random(),
                name: "Rondas",
                icon: ProntaResidencialCar
            },
            {
                id: Math.random(),
                name: "Preservação",
                icon: ProntaResidencialLike
            }
        ]
    },
    {
        id: Math.random(),
        icon: Car,
        title: "Assistência Veicular 24 horas",
        description: "A nossa Central 24 horas, está preparada para atender seus clientes em todo território nacional, do veículo leve ao extra pesado.",
        benefits: [
            {
                id: Math.random(),
                name: "Assistência mecânica e elétrica",
                icon: AssistanceTool
            },
            {
                id: Math.random(),
                name: "Remoção",
                icon: AssistanceLeftArrow
            },
            {
                id: Math.random(),
                name: "Carga de bateria",
                icon: AssistanceBattery
            },
            {
                id: Math.random(),
                name: "Troca de pneus",
                icon: AssistanceTire
            },
            {
                id: Math.random(),
                name: "Pane Seca",
                icon: AssistanceWaterLevel
            },
            {
                id: Math.random(),
                name: "Veículo reserva",
                icon: AssistanceCar
            },
            {
                id: Math.random(),
                name: "MTA - Meio de transporte alternativo",
                icon: AssistanceCar
            }
        ]
    },
    {
        id: Math.random(),
        icon: Balance,
        title: "Acompanhamento Jurídico",
        description: "Contando com Equipe Jurídica especializada no Ramo de Rastreamento e Serviços para Empresas de Monitoramento e Segurança Eletrônica, a PRMA oferece essa estrutura para seus Clientes com atuação em Eventuais Demandas Judiciais, Acompanhamento de Inquérito policial, além de Elaboração de Contratos e Implantação do Processo de Adequação à LGPD",
        benefits: [
            {
                id: Math.random(),
                name: "Atuação em demandas jurídicas",
                icon: ProntaResidencialLike
            },
            {
                id: Math.random(),
                name: "Acompanhamento de inquérito policial",
                icon: LegalEye
            },
            {
                id: Math.random(),
                name: "Elaboração de contratos",
                icon: LegalFile
            },
            {
                id: Math.random(),
                name: "Adequação à LGPD",
                icon: LegalLock
            }
        ]
    },
    {
        id: Math.random(),
        icon: File,
        title: "Sindicância",
        description: "O Serviço de Sindicância da PRMA visa compilar todos os elementos de um sinistro, com embasamento capaz de orientar qualquer decisão para o ressarcimento de um veículo sinistrado por roubo, furto ou danos. O Relatório é altamente detalhado, assinado por profissionais habilitados e revisado pelo Corpo Jurídico da PRMA FEDERAL.",
        benefits: []
    }
]