import styled from "styled-components";

export const Container = styled.div`
    margin-top: 200px;

    display: flex;
    flex-direction: column;
    align-self: stretch;

    position: relative;

    @media (max-width: 768px) {
        margin-top: 80px;

        padding: 0px 15px;
    scroll-margin-top: 90px;
    }

    scroll-margin-top: 120px;
  
`;

export const Background = styled.img`
    position: absolute;

    width: 50%;

    right: 0;

    opacity: 0.3;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: stretch;

    > div:first-of-type {
        width: 45%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    > div:last-of-type {
        width: 35%;

        @media (max-width: 768px) {
            width: 100%;

            margin-top: 3rem;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }

    z-index: 1;
`;

export const Title = styled.h1`
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 2.3rem;

    color: #FFF;

    @media (max-width: 768px) {
        font-size: 1.875rem
    }
`;

export const Description = styled.p`
    color: #B8B8B8;

    font-weight: normal;
    font-size: 1.15rem;

    @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`;

export const OrangeDescription = styled(Description)`
    color: #FF8F01;
    width: 50%;

    line-height: 32px;

    @media (max-width: 768px) {
        width: 80%;
    }
`

export const OrangeArrow = styled.img`
    width: 100%;

    margin-bottom: 1rem;
`;

export const Body = styled.div`
    margin-top: 8rem;

    display: flex;
    
    @media (max-width: 768px) {
        flex-direction: column;

        margin-top: 2.5rem;
    }
`;

export const CircleIcon = styled.div`
    width: 8px;
    height: 8px;

    border-radius: 100%;

    background: #FF8F01;

    margin-top: 7px;
`;

export const InfoCard = styled.div`
    display: flex;

    width: 33%;

    margin-right: 1rem;

    @media (max-width: 768px) {
        width: 100%;

        margin-right: 0;

        margin-top: 1.5rem;
    }
`;

export const InfoCardBody = styled.div`
    margin: 0rem 1rem;
`;

export const InfoCardTitle = styled.span`
    color: #FFF;
    font-size: 1.25rem;
    font-weight: bold;
    font-family: DM Sans;

    height: 50px;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

export const InfoCardDescription = styled.p`
    color: #B8B8B8;
    font-size: 1rem;

    @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`;

export const InfoCardHeader = styled.div`
    height: 75px;
`;