export const InfoList = [
    {
        id: Math.random(),
        title: "Ética, respeito e transparência",
        description: "Cada cliente é único e cada atendimento que realizamos é personalizado e exclusivo para a empresa que nos contrata. Nosso intuito é atuar como uma extensão do seu negócio.",
    },
    {
        id: Math.random(),
        title: "Central de Atendimento 24h completa",
        description: "Com expertise no atendimento ao cliente e uma gama de serviços, somos capazes de atender ao seu cliente desde o início do relacionamento.",
    },
    {
        id: Math.random(),
        title: "6 mil prestadores de serviço",
        description: "Aproximadamente 6 mil prestadores de serviço credenciados em todo o território nacional. Para assistência veicular 24 horas são mais de 5 mil agentes de pronta resposta.",
    },
]