import {
    Container,
    ShieldLogo,
    Title,
    Row,
    Icon,
    IconContainer,
    ShieldContainer,
} from "./styles";

import shieldLogo from "../../img/shield.svg";

import Eye from "../../img/benefits/central/eye.svg"; // Ícone
import Car from "../../img/benefits/veicular-patrimony/car.svg";
import Home from "../../img/benefits/residencial-patrimony/home.svg";
import Balance from "../../img/benefits/legal-monitoring/balance.svg";
import Shield from '../../img/protect 1.svg'
import File from '../../img/file-focus 1.svg'
import { Button } from "../Button";


function Banner() {
    function renderIcon(image) {
        return (
            <IconContainer>
                <Icon src={image} />
            </IconContainer>
        )
    }

    const handleClick = () => {
        window.open("https://api.whatsapp.com/send?phone=5511984715242&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20contratar%20a%20PRMA.");
      };

    return (
        <Container>
            <div>
                <Title>Esteja seguro. Venha conosco.</Title>
                <Button onClick={handleClick}>Contratar a PRMA</Button>
            </div>
            <ShieldContainer>
                <ShieldLogo src={shieldLogo} />
            </ShieldContainer>
            <div>
                <Row>
                    {renderIcon(Eye)}
                    {renderIcon(Car)}
                    {renderIcon(Home)}
                </Row>
                <Row>
                    {renderIcon(Balance)}
                    {renderIcon(Shield)}
                    {renderIcon(File)}
                </Row>
            </div>
        </Container>
    )
};

export { Banner };