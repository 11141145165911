import styled from "styled-components";

export const Container = styled.div`

`;

export const Column = styled.div`

`;

export const Title = styled.p`
    color: white;
    width: 474px;
    height: 180px;
    padding-bottom: 32px;
    font-weight: 500;
    font-size: 56px;
    line-height: 60px;
    font-family: 'DM Sans', sans-serif;
  
    @media(max-width: 768px) {
        width: 309px;
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 35px;
        text-align: center;
        margin: auto;
        height: auto;

    }
`
export const Subtitle = styled.p`
    width: 450px;
    height: 64px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 32px;
    color: #B8B8B8;
    padding-bottom: 42px;
    @media(max-width: 768px) {
        width: 295px;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
        margin: auto;
        height: auto;
        padding-bottom: 0px;
    })
`
export const Row = styled.div`
    display: flex;
    justify-content: center;
`;

export const HeaderContent = styled.div`
    display: flex;
    align-self: stretch;
    margin-top: 40px;
    justify-content: space-between;

    z-index: -1;

    @media(max-width: 768px){
        margin-top: 72px;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    position: relative;

    @media(max-width: 768px) {
        justify-content: center;

        margin-top: 2.5rem;
    }
`;

export const TextsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(max-width: 768px) {
        width: 100%;
    }
`

export const MobileText = styled.p`
    @media(min-width: 768px) {
        display: none;
    }
`;

export const Text = styled.p`
    @media(max-width: 768px) {
        display: none;
    }
`;

export const Card = styled.div`
    background: #191919;

    border-radius: 4px;

    cursor: pointer;

    margin-right: 32px;

    width: 380px;

    @media (max-width: 768px) {
        width: 70vw;

        margin-right: 0px;

        &:first-of-type {
            margin-bottom: 20px;
        }
    }
`;

export const CardImage = styled.img`
    width: 100%;
    max-height: 100%;

    border-radius: 4px 4px 0px 0px;

    object-fit: cover;
`;

export const CardBody = styled.div`
    height: 100px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 2rem;

    @media (max-width: 768px) {
        height: 100px;
    }
`;

export const CardHeader = styled.div`
    width: 380px;
    height: 220px;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
`;

export const CardTitle = styled.span`
    width: 80%;

    font-size: 1.2rem;
    font-weight: 500;
    color: #EBEBEB;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

export const ArrowIcon = styled.img``;

export const ModalContent = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;