import styled from "styled-components";

export const HomeContainer = styled.div`
scroll-margin-top: 5em;
  /* border: 1px solid red; */

@media (min-width: 768px) and (max-width: 1280px){
  left: -120px;
  overflow-x: hidden;
  width: 100vw;
}

@media (min-device-width: 768px) and (max-device-width: 1024px){
  overflow-x: visible;
}

@media(max-width: 768px){
  margin-left: 0;
}
`

export const ShieldStyle = styled.div`
position: absolute;
width: 317.92px;
height: 152.96px;
left: 597px;
top: 58px;
`

export const Background = styled.img`
position: absolute;
width: 1600px;
height: 600px;
@media(max-width: 768px){
  display: none;
}
`

export const GlobalContainer = styled.div`

  @media (max-width: 768px) {
    width: 100vw;
    margin: 0 auto;
  }

  @media (max-width: 992px) and (min-width: 768px){
    width: 90%;
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    width: 960px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    width: 1140px;
    margin: 0 auto;
  }

  @media (min-width: 1400px) { 
    width: 1320px;
    margin: 0 auto;
  }
`
export const MainModalContainer = styled.div`
@media(max-width: 768px){
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
}
`

export const BenefitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  align-self: stretch;
  scroll-margin-top: 120px;
  @media screen and (max-width: 768px) {
    scroll-margin-top: 90px;
  }
`;