import { 
    CardContent,
    CardButton,
    Text,
    Icon,
    ArrowIcon,
    IconContainer
} from "./styles";

import arrowIcon from "../../img/Seta.png";

function BenefitButton(props) {
    const benefit = props.benefit;

    return (
        <CardButton onClick={() => props.onBenefitClick(benefit)}>
            <CardContent>
                <IconContainer>
                    <Icon src={benefit.icon}/>
                </IconContainer>
                <Text>{benefit.title}</Text>
            </CardContent>

            <ArrowIcon src={arrowIcon} />
        </CardButton>
    )
};

export { BenefitButton };