import { useState } from "react";

import {
    HomeContainer, 
    Title, 
    Subtitle,
    Background, 
    GlobalContainer, 
    BenefitContainer,
} from './style'
import {TextOne, TextTwo} from '../../components/HomeComponents/texts'
import {HireButton} from '../../components/Buttons/hire-button'
import {ProviderButton} from '../../components/Buttons/provider-button'

import About from '../../components/About'
import { Navbar } from '../../components/Navbar'
import { Modal } from '../../components/Modal'
import Assistant from '../../components/Assistant'
import RecoveredCars from '../../components/RecoveredCars'
import Testimony from '../../components/Testimony'
import Footer from '../../components/Footer'
import Map from '../../img/default_map (Traced).svg'
import { HeaderContent, TextsContainer } from '../../components/HomeComponents/style'
import { ButtonsContainer } from '../../components/Buttons/styles'

import { BenefitButton } from "../../components/BenefitButton";
import { BenefitList } from "./data";
import { ServiceModal } from "../../components/ServiceModal";
import { AboutUs } from "../../components/AboutUs";
import { BeAnAssistant } from "../../components/BeAnAssistant";
import { Banner } from "../../components/Banner";

import { Header } from "../../components/Header";

export default function Home() {
    const [openModal, setOpenModal] = useState(false);

    const [selectedBenefit, setSelectedBenefit] = useState();

    function onBenefitClick(benefit) {
        setSelectedBenefit(benefit);
        setOpenModal(true);
    }

    return (
        <HomeContainer id="inicio">
            <GlobalContainer>
                <Background src={Map}/>
                <Navbar />

                <Header />
                
                <BenefitContainer id="services">
                    {BenefitList.map((benefit, index) => (
                        <BenefitButton 
                            key={index}
                            onBenefitClick={onBenefitClick}
                            benefit={benefit}
                        />
                    ))}
                </BenefitContainer>

                <Modal open={openModal} handleClose={() => setOpenModal(false)}>
                    <ServiceModal service={selectedBenefit} handleClose={() => setOpenModal(false)}/>
                </Modal>

                <AboutUs />

                <BeAnAssistant />

                <RecoveredCars />

                {/* <Testimony /> */}

                <Banner />

                <Footer />
             
            </GlobalContainer>
        </HomeContainer>
    )}